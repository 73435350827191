<template>
  <v-card>
    <v-card-text style="max-height: 400px; overflow: auto">
      <v-form ref="form" lazy-validation class="mx-2 px-2">
        <v-row>
          <input ref="refInputEl" hidden="true" @change="ChangeImg" type="file" accept=".jpeg,.png,.jpg,GIF" multiple />
          <v-col>
            <v-card
              v-if="dataPrevImg.length == 0"
              class="d-flex align-center text-center"
              dark
              height="200"
              @click="$refs.refInputEl.click()"
            >
              <v-scroll-y-transition>
                <div class="text-h5 flex-grow-1 text-center">Seleccionar Imagen</div>
              </v-scroll-y-transition>
            </v-card>
          </v-col>
        </v-row>
      </v-form>
      <v-row style="margin-top: 2rem; scroll-behavior: auto" v-if="dataPrevImg.length > 0">
        <v-col cols="3" v-for="(item, i) in dataPrevImg" :key="i">
          <v-item v-slot="{ active, toggle }">
            <v-card link @click="toggle">
              <div>
                <v-img :src="item.url" height="150" contain>
                  <v-row class="fill-height ma-0" align="center" justify="center" v-if="item.loading">
                    <v-progress-circular indeterminate color="primary"></v-progress-circular>
                  </v-row>
                  <v-row v-else>
                    <v-col cols="12" class="text-right pr-4 pt-4">
                      <v-btn
                        icon
                        class="white"
                        @click="DeleteImg(i)"
                        v-if="item.loading == false && item.upload == false"
                      >
                        <v-icon color="error">
                          {{ icons.mdiDeleteOutline }}
                        </v-icon>
                      </v-btn>

                      <v-btn icon class="white" v-if="item.loading == false && item.upload == true">
                        <v-icon color="success">
                          {{ icons.mdiCheckCircle }}
                        </v-icon>
                      </v-btn>

                      <v-btn
                        icon
                        class="white"
                        @click="DeleteImg(i)"
                        v-if="item.loading == false && item.upload == false && item.error == true"
                      >
                        <v-icon color="error">
                          {{ icons.mdiCloseCircle }}
                        </v-icon>
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-img>
                <v-card-text>
                  <v-row>
                    <v-col cols="12" class="pt-4 my-0 text-left">
                      <small>
                        <strong>{{ item.name.length > 30 ? item.name.substring(0, 30) + '...' : item.name }} </strong>
                      </small>
                    </v-col>
                    <v-col cols="12" class="pt-1 my-0">
                      <small> {{ item.size }} Byte </small>
                    </v-col>
                  </v-row>
                </v-card-text>
              </div>
            </v-card>
          </v-item>
        </v-col>
      </v-row>
    </v-card-text>
    <v-divider></v-divider>
    <v-card-text class="text-right">
      <v-btn
        small
        color="secondary"
        class="mr-1"
        @click="ClearModal"
        :loading="completado.count > 0 && completado.count != completado.listo"
        :disabled="completado.count > 0 && completado.count != completado.listo"
        >Limpiar
      </v-btn>
      <v-btn
        small
        color="success"
        :loading="completado.count > 0 && completado.count != completado.listo"
        :disabled="this.dataPrevImg.length == 0 || completado.count > 0"
        @click="SaveImg"
        >Subir</v-btn
      >
    </v-card-text>
  </v-card>
</template> 
<script>
import { ref } from '@vue/composition-api'
import {
  mdiCheckboxMarkedOutline,
  mdiCheckboxBlankOutline,
  mdiImageEditOutline,
  mdiCloudUploadOutline,
  mdiDeleteOutline,
  mdiCheckCircle,
  mdiCloseCircle,
} from '@mdi/js'
import ImagenServices from '@/api/servicios/ImagenServices'
export default {
  methods: {
    onFileChange($event) {
      console.log($event)
    },
  },
  watch: {
    'completado.listo': function () {
      if (this.completado.count > 0 && this.completado.count == this.completado.listo) {
        this.SendImg()
      }
    },
  },
  
  setup(props, context) {
    const ModalFormRef = ref(null)
    const files = null
    const selected = ref([])
    const dialog = ref(false)
    const completado = ref({ count: 0, listo: 0, errores: 0 })

    const dataPrevImg = ref([])
    const ChangeImg = e => {
      const imagenes = []
      e.target.files.forEach((element, i) => {
        const url = URL.createObjectURL(element)
        imagenes.push({
          img: i,
          url,
          name: element.name,
          size: element.size,
          element,
          loading: false,
          upload: false,
          error: false,
        })
      })
      dataPrevImg.value = imagenes
      console.log(dataPrevImg.value)
    }
    const resultados = ref([])
    const SendImg = () => {
      context.emit('sendImg', {
        control: completado.value,
        datos: resultados.value,
      })
      if (completado.value.errores == 0) {
        ClearModal() 
      }
    } 
    const SaveImg = async () => {
      completado.value.count = dataPrevImg.value.length
      completado.value.listo = 0
      completado.value.errores = 0
      await dataPrevImg.value.forEach(async x => {
        x.loading = true
        const form = new FormData()
        form.append('Archivo', x.element)
        //SUBIR IMAGEN
        // console.log('asq')
        await ImagenServices.subirImg(form)
          .then(response => {
            console.log(response.data)
            if (response.data.estatus == true) {
              resultados.value.push(response.data.datos.datos)
              x.loading = false
              x.upload = true
            } else {
              x.loading = false
              x.upload = false
              x.error = true
              completado.value.errores += 1
            }
            //imagen = response.data.img
          })
          .catch(error => {
            console.log(error)
            x.loading = false
            x.upload = false
            x.error = true
            completado.value.errores += 1
          })
          .finally(() => {
            completado.value.listo += 1
          })
      })
      //dataPrevImg.value = [];
    }

    const DeleteImg = i => {
      dataPrevImg.value.splice(i, 1)
    }

    const ClearModal = () => {
      dataPrevImg.value = []
      completado.value = { count: 0, listo: 0 }
    }

    return {
      ModalFormRef,
      ChangeImg,
      SaveImg,
      DeleteImg,
      ClearModal,
      dialog,
      files,
      selected,
      completado,
      dataPrevImg,
      SendImg,
      icons: {
        mdiCheckboxMarkedOutline,
        mdiCheckboxBlankOutline,
        mdiImageEditOutline,
        mdiCloudUploadOutline,
        mdiDeleteOutline,
        mdiCheckCircle,
        mdiCloseCircle,
      },
    }
  },
}
</script>