<template>
  <v-card>
    <v-card-text>
      <v-tabs v-model="tab">
        <v-tab :key="1">Imagenes</v-tab>
        <v-tab :key="2" v-if="_selected">
          <v-badge color="primary" :content="selected.length > 0 ? selected.length : '0'"> Seleccionadas </v-badge>
        </v-tab>
      </v-tabs>
    </v-card-text>
    <v-card-text style="max-height: 400px; overflow: auto">
      <v-tabs-items v-model="tab">
        <v-tab-item :key="1">
          <v-card flat>
            <v-card-text>
              <div class="text-right pt-3">
                <v-btn class="mr-2 mb-2" color="primary" small @click="Abrir()"> Subir imagenes </v-btn>
              </div>
              <v-dialog v-model="dialog" width="700" scrollable>
                <v-card>
                  <v-card-title>
                    Subir Imagen
                    <v-spacer></v-spacer>
                    <v-btn icon color="secondary" @click="dialog = false">
                      <v-icon>{{ icons.mdiClose }}</v-icon>
                    </v-btn>
                  </v-card-title>
                  <Upload @sendImg="sendImgUpload" ref="UploadImg" />
                </v-card>
              </v-dialog>
              <v-container>
                <v-row>
                  <v-col cols="12" sm="6" md="4" lg="3" v-for="items in datos.registros" :key="items.id">
                    <v-item v-slot="{ active, toggle }" :disabled="!_selected">
                      <v-card link @click="sendImgSelect(items)">
                        <div>
                          <v-img :src="`${config.api_imagenes}${items.formats.small.ruta}`" height="150" contain>
                            <v-row>
                              <v-col cols="6" class="text-left pl-4 pt-4">
                                <v-btn icon class="white success--text" v-if="_selected">
                                  <v-icon>
                                    {{
                                      isSelected(items) ? icons.mdiCheckboxMarkedOutline : icons.mdiCheckboxBlankOutline
                                    }}
                                  </v-icon>
                                </v-btn>
                              </v-col>
                              <v-col cols="6" class="text-right pr-4 pt-4">
                                <v-btn icon class="white" @click="toggle">
                                  <v-icon>
                                    {{ icons.mdiImageEditOutline }}
                                  </v-icon>
                                </v-btn>
                              </v-col>
                            </v-row>
                            <template v-slot:placeholder>
                              <v-row class="fill-height ma-0" align="center" justify="center">
                                <v-progress-circular indeterminate color="grey lighten-5"></v-progress-circular>
                              </v-row>
                            </template>
                          </v-img>

                          <v-card-text>
                            <v-row>
                              <v-col cols="12" class="pt-4 my-0">
                                <small>
                                  <strong>{{
                                    items.nombre.length > 30 ? items.nombre.substring(0, 30) + '...' : items.nombre
                                  }}</strong></small
                                >
                              </v-col>

                              <v-col cols="6" class="py-0 my-0">
                                <small> {{ items.extencion }}-{{ items.alto }}X{{ items.ancho }} </small>
                              </v-col>

                              <v-col cols="6" class="py-0 my-0 text-right">
                                <small> {{ items.tamano }} byte</small>
                              </v-col>
                            </v-row>
                          </v-card-text>
                        </div>
                      </v-card>
                    </v-item>
                  </v-col>
                  <v-col cols="12"> </v-col>
                </v-row>
              </v-container>
            </v-card-text>
          </v-card>
        </v-tab-item>
        <v-tab-item :key="2">
          <v-card flat>
            <v-card-text>
              <v-container>
                <v-row>
                  <v-col cols="12" sm="6" md="4" lg="3" v-for="items in selected" :key="items.id">
                    <v-item :disabled="!_selected">
                      <v-card link @click="sendImgSelect(items)">
                        <div>
                          <v-img :src="`${config.api_imagenes}${items.formats.small.ruta}`" height="150" contain>
                            <v-row>
                              <v-col cols="6" class="text-left pl-4 pt-4">
                                <v-btn icon class="white success--text" v-if="_selected">
                                  <v-icon>
                                    {{
                                      isSelected(items) ? icons.mdiCheckboxMarkedOutline : icons.mdiCheckboxBlankOutline
                                    }}
                                  </v-icon>
                                </v-btn>
                              </v-col>
                              <v-col cols="6" class="text-right pr-4 pt-4">
                                <v-btn icon class="white" @click="toggle">
                                  <v-icon>
                                    {{ icons.mdiImageEditOutline }}
                                  </v-icon>
                                </v-btn>
                              </v-col>
                            </v-row>
                            <template v-slot:placeholder>
                              <v-row class="fill-height ma-0" align="center" justify="center">
                                <v-progress-circular indeterminate color="grey lighten-5"></v-progress-circular>
                              </v-row>
                            </template>
                          </v-img>

                          <v-card-text>
                            <v-row>
                              <v-col cols="12" class="pt-4 my-0">
                                <small>
                                  <strong>{{
                                    items.nombre.length > 30 ? items.nombre.substring(0, 30) + '...' : items.nombre
                                  }}</strong></small
                                >
                              </v-col>

                              <v-col cols="6" class="py-0 my-0">
                                <small> {{ items.extencion }}-{{ items.alto }}X{{ items.ancho }} </small>
                              </v-col>

                              <v-col cols="6" class="py-0 my-0 text-right">
                                <small> {{ items.tamano }} byte</small>
                              </v-col>
                            </v-row>
                          </v-card-text>
                        </div>
                      </v-card>
                    </v-item>
                  </v-col>
                  <v-col cols="12"> </v-col>
                </v-row>
              </v-container>
            </v-card-text>
          </v-card>
        </v-tab-item>
      </v-tabs-items>
    </v-card-text>
    <v-divider></v-divider>
    <v-card-actions>
      <div class="text-center" v-if="tab == 0">
        <v-pagination
          v-model="filtro.Pagina"
          :length="datos.paginas"
          :total-visible="7"
          :disabled="cargando"
        ></v-pagination>
        <v-progress-linear v-show="cargando" indeterminate color="primary"></v-progress-linear>
      </div>
      <v-spacer></v-spacer>
      <v-btn
        v-if="_selected"
        @click="Terminar()"
        :disabled="selected.length == 0"
        class="mr-2 mb-2"
        color="primary"
        small
      >
        Terminar
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { onBeforeMount, ref } from '@vue/composition-api'
import { mdiCheckboxMarkedOutline, mdiCheckboxBlankOutline, mdiImageEditOutline, mdiClose } from '@mdi/js'
import ImagenServices from '@/api/servicios/ImagenServices'

import Upload from './Upload.vue'
import config from '@/api/config'
export default {
  props: {
    _selected: {
      type: Boolean,
      default: true,
    },
    _multiSelected: {
      type: Boolean,
      default: true,
    },
  },
  components: {
    Upload,
  },
  watch: {
    'filtro.Pagina': function () {
      this.consultar()
    },
  },

  setup(props, context) {
    const dialog = ref(false)
    const Abrir = () => {
      dialog.value = true
    }
    const tab = ref(0)
    const selected = ref([])
    const filtro = ref({
      Valor: '',
      Pagina: 1,
      Limite: 8,
    })
    const datos = ref({
      total: 0,
      paginas: 0,
      registros: [],
    })
    const sendImgUpload = datos => {
      filtro.value = {
        Valor: '',
        Pagina: 1,
        Limite: 8,
      }
      consultar()
      if (datos.control.errores == 0) {
        dialog.value = false
      }
    }
    const cargando = ref(false)
    onBeforeMount(() => {
      consultar()
      console.log('aas ')
    })

    const Terminar = () => {
      context.emit('sendImgSelect', selected.value)
    }

    const consultar = () => {
      cargando.value = true
      ImagenServices.ConsultarImagenesPaginado(filtro.value)
        .then(response => {
          if (response.data.estatus == true) {
            datos.value = response.data.datos
            console.log(datos)
          } else {
            console.log(response.data.mensaje)
          }
        })
        .catch(err => {
          console.log(err)
        })
        .finally(() => {
          cargando.value = false
        })
    }
    const imgSelect = ref([])
    const getImg = items => {
      imgSelect.value.push(items)
      // console.log(imgSelect)
    }

    const isSelected = item => {
      if (selected.value.find(it => item.id == it.id)) {
        return true
      }

      return false
    }
    const sendImgSelect = items => {
      if (props._multiSelected == false) {
        if (isSelected(items)) {
          selected.value = []
        } else {
          selected.value = [items]
        }
      } else {
        if (isSelected(items)) {
          selected.value = selected.value.filter(it => it.id != items.id)
        } else {
          selected.value.push(items)
        }
      }
    }

    return {
      datos,
      selected,
      cargando,
      consultar,
      isSelected,
      config,
      filtro,
      tab,
      Terminar,
      icons: {
        mdiCheckboxMarkedOutline,
        mdiCheckboxBlankOutline,
        mdiImageEditOutline,
        mdiClose,
      },
      imgSelect,
      getImg,
      sendImgSelect,
      dialog,
      Abrir,
      sendImgUpload,
    }
  },
}
</script>