import Axios from 'axios';
import store from '@/store'
import http from "..";
import router from '@/router'

class ImagenServices {
  subirImg(data) {
    return http.post("/imagen/subir", data, {
      headers: { "Content-Type": "multipart/form-data" }
    })
      .catch((error) => {
        if (error.response.status == 403) {
          store.commit("setUserData", { id: 0, userName: "", IsAutenticated: false });
          router.push("/pages/login");
        }
      })
  }

  ConsultarImagenesPaginado(datos) {
    return http.get("imagen/consultar/paginado", { params: datos })
      .catch((error) => {
        if (error.response.status == 401) {
          store.commit("setUserData", { id: 0, userName: "", isAutenticated: false });
          router.push("/pages/login");
        }
      });
  }
}

export default new ImagenServices;